import trending1 from "../assets/trending/trending1.webp"
import trending2 from "../assets/trending/trending2.webp"
import trending3 from "../assets/trending/trending3.webp"
import trending4 from "../assets/trending/trending4.webp"
import trending5 from "../assets/trending/trending5.webp"
import trending6 from "../assets/trending/trending6.webp"
import trending7 from "../assets/trending/trending7.jpg"
import trending8 from "../assets/trending/trending8.jpg"
import trending9 from "../assets/trending/trending9.webp"
import trending10 from "../assets/trending/trending10.jpg"
import trending11 from "../assets/trending/trending11.webp"
import trending12 from "../assets/trending/trending12.webp"

export const trending = [
  {
    "id": 696,
    "title": "Toán 4, 5 | Bài toán bán trứng | Kiến thức phân số của một số | Thầy Tài",
    "slug": "code-thieu-nhi-battle-tranh-gianh-tra-sua-size-l",
    "image": trending1,
    "video": "sgq7BH6WxL8",
    "duration": 1510,
    "yt_view_count": 4147,
    "yt_comment_count": 14,
    "yt_like_count": 281,
    "image_url": "https://i.ytimg.com/vi/sgq7BH6WxL8/maxresdefault.jpg",
    "video_url": "https://youtu.be/lybnjr7CyMs?si=33YOl41TTVn6U6xR"
  },
  {
    "id": 760,
    "title": "Toán 4, 5 | Tư duy tìm X siêu đơn giản | Thầy Dương Tiến Tài",
    "slug": "sinh-vien-it-di-thuc-tap-tai-doanh-nghiep-can-biet-nhung-gi",
    "image": trending2,
    "video": "YH-E4Y3EaT4",
    "duration": 2091,
    "yt_view_count": 2881,
    "yt_comment_count": 28,
    "yt_like_count": 147,
    "image_url": "https://i.ytimg.com/vi/YH-E4Y3EaT4/maxresdefault.jpg",
    "video_url": "https://youtu.be/pmNIUR1wFIU?si=I-vNdsJWojWSRk3e"
  },
  {
    "id": 456,
    "title": "Toán 4 | Bài tập ĐIỂM 10 | Biểu thức phân số có quy luật",
    "slug": "phuong-phap-hoc-lap-trinh-cua-admin-f8",
    "image": trending3,
    "video": "DpvYHLUiZpc",
    "duration": 1446,
    "yt_view_count": 2484,
    "yt_comment_count": 29,
    "yt_like_count": 157,
    "image_url": "https://i.ytimg.com/vi/DpvYHLUiZpc/maxresdefault.jpg",
    "video_url": "https://youtu.be/JTY08ii9l-4?si=9t-oX9Xi2YKqQRBt"
  },
  {
    "id": 533,
    "title": "Toán 4 | Tính bằng cách thuận tiện nhất | Biểu thức phân số (p2)",
    "slug": "lam-sao-de-co-thu-nhap-cao-va-di-xa-hon-trong-nganh-it",
    "image": trending4,
    "video": "oF7isq9IjZM",
    "duration": 1540,
    "yt_view_count": 5113,
    "yt_comment_count": 216,
    "yt_like_count": 268,
    "image_url": "https://i.ytimg.com/vi/oF7isq9IjZM/maxresdefault.jpg",
    "video_url": "https://youtu.be/E-2BdRq-7TU?si=cEPwCfI78LNsz4Rq"
  },
  {
    "id": 44,
    "title": "Toán 4 | Tính bằng cách thuận tiện nhất | Biểu thức phân số (p1)",
    "slug": "ban-se-lam-duoc-gi-sau-khoa-hoc",
    "image": trending5,
    "video": "R6plN3FvzFY",
    "duration": 195,
    "yt_view_count": 8310,
    "yt_comment_count": 107,
    "yt_like_count": 253,
    "image_url": "https://i.ytimg.com/vi/R6plN3FvzFY/maxresdefault.jpg",
    "video_url": "https://youtu.be/4w6DQC1Y_zY?si=ovbIK8lJujowEQge"
  },
  {
    "id": 690,
    "title": "Toán 4 | Phân số (p6) - Tư duy THÊM-BỚT vào tử số và mẫu số",
    "slug": "reactjs-la-gi-tai-sao-nen-hoc-reactjs",
    "image": trending6,
    "video": "x0fSBAgBrOQ",
    "duration": 641,
    "yt_view_count": 6215,
    "yt_comment_count": 340,
    "yt_like_count": 275,
    "image_url": "https://i.ytimg.com/vi/x0fSBAgBrOQ/maxresdefault.jpg",
    "video_url": "https://youtu.be/-MAFiTl0WWw?si=yJ_rM4TtgGVJyyTs"
  },
  {
    "id": 692,
    "title": "Toán 4 | Phân số (p5) - Tìm X dựa vào tính chất phân số",
    "slug": "javascript-co-the-lam-duoc-gi-gioi-thieu-qua-ve-trang-f8-hoc-lap-trinh-javasc",
    "image": trending7,
    "video": "0SJE9dYdpps",
    "duration": 473,
    "yt_view_count": 3380,
    "yt_comment_count": 99,
    "yt_like_count": 145,
    "image_url": "https://i.ytimg.com/vi/0SJE9dYdpps/maxresdefault.jpg",
    "video_url": "https://youtu.be/HyUaCS5kKGA?si=vyinCn_pxFFq4Tw8"
  },
  {
    "id": 691,
    "title": "Toán 4 | Phân số (p4) - 3 CÁCH quy đồng mẫu số",
    "slug": "lan-tro-lai-nay-f8-se-lam-gi-cho-cac-ban-hoc-lap-trinh-de-di-lam-tai-f8-nao",
    "image": trending8,
    "video": "ZGmpvhqYSDU",
    "duration": 396,
    "yt_view_count": 1457,
    "yt_comment_count": 42,
    "yt_like_count": 191,
    "image_url": "https://i.ytimg.com/vi/ZGmpvhqYSDU/maxresdefault.jpg",
    "video_url": "https://youtu.be/Mjl5bMKnAxY?si=P2XT0iAKS_RHw1Q2"
  },
  {
    "id": 69,
    "title": "Toán 4 | Phân số (p3) - 5 CÁCH RÚT GỌN PHÂN SỐ hay nhất",
    "slug": "lan-tro-lai-nay-f8-se-lam-gi-cho-cac-ban-hoc-lap-trinh-de-di-lam-tai-f8-nao",
    "image": trending9,
    "video": "ZGmpvhqYSDU",
    "duration": 36,
    "yt_view_count": 1545,
    "yt_comment_count": 422,
    "yt_like_count": 131,
    "image_url": "https://i.ytimg.com/vi/ZGmpvhqYSDU/maxresdefault.jpg",
    "video_url": "https://youtu.be/SgH-HPnFbcE?si=R5qUib8-IuWp4roN"
  },
  {
    "id": 689,
    "title": "Toán 4 | Phân số cơ bản nhất (p2)",
    "slug": "lan-tro-lai-nay-f8-se-lam-gi-cho-cac-ban-hoc-lap-trinh-de-di-lam-tai-f8-nao",
    "image": trending10,
    "video": "ZGmpvhqYSDU",
    "duration": 396,
    "yt_view_count": 4223,
    "yt_comment_count": 422,
    "yt_like_count": 123,
    "image_url": "https://i.ytimg.com/vi/ZGmpvhqYSDU/maxresdefault.jpg",
    "video_url": "https://youtu.be/w_EWwUv3n-Y?si=ud0XMgiJWl6jYWUw"
  },
  {
    "id": 688,
    "title": "Toán 4 | Phân số cơ bản nhất (p1)",
    "slug": "lan-tro-lai-nay-f8-se-lam-gi-cho-cac-ban-hoc-lap-trinh-de-di-lam-tai-f8-nao",
    "image": trending11,
    "video": "ZGmpvhqYSDU",
    "duration": 396,
    "yt_view_count": 2239,
    "yt_comment_count": 52,
    "yt_like_count": 121,
    "image_url": "https://i.ytimg.com/vi/ZGmpvhqYSDU/maxresdefault.jpg",
    "video_url": "https://youtu.be/GFATcfY-yI0?si=4LEa_ZkJcgfnbGzb"
  },
  {
    "id": 689,
    "title": "Toán 2, 3 | Phương pháp lập sơ đồ phép tính giải BÀI TOÁN TĂNG, GIẢM SỐ HẠNG (dễ hiểu nhất)",
    "slug": "lan-tro-lai-nay-f8-se-lam-gi-cho-cac-ban-hoc-lap-trinh-de-di-lam-tai-f8-nao",
    "image": trending12,
    "video": "ZGmpvhqYSDU",
    "duration": 396,
    "yt_view_count": 1339,
    "yt_comment_count": 82,
    "yt_like_count": 91,
    "image_url": "https://i.ytimg.com/vi/ZGmpvhqYSDU/maxresdefault.jpg",
    "video_url": "https://youtu.be/X59AwY3rrng?si=T5N38opGJMPKGe5M"
  }
]