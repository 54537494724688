import React, { useState } from 'react';
import { Tabs, Modal, Card, Row, Col } from 'antd';
import './document.css';

const classData = [
  {
    className: 'Lớp 2',
    files: [
      {
        url: 'https://drive.google.com/file/d/1kwM49u4fTc_hMbfxCJ2u676XNBlMBSCB/preview',
        title: 'Bài toán tăng, giảm số bị trừ và số trừ',
        description: '02_PHƯƠNG PHÁP GIẢI BÀI TOÁN TĂNG, GIẢM SỐ BỊ TRỪ VÀ SỐ TRỪ',
        cover: 'https://drive.google.com/thumbnail?id=1kwM49u4fTc_hMbfxCJ2u676XNBlMBSCB&sz=w1200-h800',
      },
      {
        url: 'https://drive.google.com/file/d/1Lw_2iWC47jzrgh7HtKDE3wJ1s2rrqIkg/preview',
        title: 'Bài toán tăng, giảm số hạng',
        description: '01_BÀI TOÁN TĂNG, GIẢM SỐ HẠNG',
        cover: 'https://drive.google.com/thumbnail?id=1Lw_2iWC47jzrgh7HtKDE3wJ1s2rrqIkg&sz=w1200-h800',
      },
      // {
      //   url: 'https://drive.google.com/file/d/1kkfl7IJuW-cGKgcTuYAfiZ7CCdJld1Tl/preview',
      //   title: '06_Toán 1 lên 2',
      //   description: '06_Phép cộng, phép trừ trong phạm vi 100 (tiếp)',
      //   cover: 'https://drive.google.com/thumbnail?id=1kkfl7IJuW-cGKgcTuYAfiZ7CCdJld1Tl&sz=w1200-h800',
      // },
      // {
      //   url: 'https://drive.google.com/file/d/1cyIveX9LePfrRquaphY8XsM6B5mk__tT/preview',
      //   title: '16_Toán 2',
      //   description: '16_BÀI TOÁN NHIỀU HƠN, ÍT HƠN',
      //   cover: 'https://drive.google.com/thumbnail?id=1cyIveX9LePfrRquaphY8XsM6B5mk__tT&sz=w1200-h800',
      // },
    ],
  },
  {
    className: 'Lớp 3',
    files: [
      // {
      //   url: 'https://drive.google.com/file/d/1ICi6PS4BNiKzT3wuONHcsocOKaBURAp7/preview',
      //   title: '05_Toán 2 lên 3',
      //   description: '05_Phép cộng, phép trừ trong phạm vi 1 000',
      //   cover: 'https://drive.google.com/thumbnail?id=1ICi6PS4BNiKzT3wuONHcsocOKaBURAp7&sz=w1200-h800',
      // },
      {
        url: 'https://drive.google.com/file/d/1kwM49u4fTc_hMbfxCJ2u676XNBlMBSCB/preview',
        title: 'Bài toán tăng, giảm số bị trừ và số trừ',
        description: '02_PHƯƠNG PHÁP GIẢI BÀI TOÁN TĂNG, GIẢM SỐ BỊ TRỪ VÀ SỐ TRỪ',
        cover: 'https://drive.google.com/thumbnail?id=1kwM49u4fTc_hMbfxCJ2u676XNBlMBSCB&sz=w1200-h800',
      },
      {
        url: 'https://drive.google.com/file/d/1Lw_2iWC47jzrgh7HtKDE3wJ1s2rrqIkg/preview',
        title: 'Bài toán tăng, giảm số hạng',
        description: '01_BÀI TOÁN TĂNG, GIẢM SỐ HẠNG',
        cover: 'https://drive.google.com/thumbnail?id=1Lw_2iWC47jzrgh7HtKDE3wJ1s2rrqIkg&sz=w1200-h800',
      },
    ],
  },
  {
    className: 'Lớp 4',
    files: [
      // {
      //   url: 'https://drive.google.com/file/d/1yJxeR4-R2E8QdBhx-VAXRn0Stq--D0IK/preview',
      //   title: '08_Toán 3 lên 4',
      //   description: '08_LUYỆN TẬP',
      //   cover: 'https://drive.google.com/thumbnail?id=1yJxeR4-R2E8QdBhx-VAXRn0Stq--D0IK&sz=w1200-h800',
      // },
      // {
      //   url: 'https://drive.google.com/file/d/1FgbZ5n6w2_J8qHFe1-ohBTzn-bNJdQGN/preview',
      //   title: '09_Ôn hè Toán 3 lên 4',
      //   description: '09_Ôn tập các dạng toán lời văn quan trọng',
      //   cover: 'https://drive.google.com/thumbnail?id=1FgbZ5n6w2_J8qHFe1-ohBTzn-bNJdQGN&sz=w1200-h800',
      // },
    ],
  },
  {
    className: 'Lớp 5',
    files: [
      {
        url: 'https://drive.google.com/file/d/1gytGKqcCNUgZA_Nhd0EzatSQeaTvoe8b/preview',
        title: 'Tính thuận tiện biểu thức số thập phân',
        description: '35_TÍNH THUẬN TIỆN BIỂU THỨC SỐ THẬP PHÂN',
        cover: 'https://drive.google.com/thumbnail?id=1gytGKqcCNUgZA_Nhd0EzatSQeaTvoe8b&sz=w1200-h800',
      },
      // {
      //   url: 'https://drive.google.com/file/d/1MGrAU5oT9G-hehepFGXLztVupI5oGd-f/preview',
      //   title: '07_Toán 4 lên 5',
      //   description: '07_PHÂN SỐ VÀ CÁC PHÉP TÍNH VỚI PHÂN SỐ',
      //   cover: 'https://drive.google.com/thumbnail?id=1MGrAU5oT9G-hehepFGXLztVupI5oGd-f&sz=w1200-h800',
      // },
      // {
      //   url: 'https://drive.google.com/file/d/1cRfuKLQzNYYhSpDY4r_JQB5tGF-1cfGd/preview',
      //   title: '09_Toán 5',
      //   description: '09_BÀI TOÁN LIÊN QUAN ĐẾN PHÂN SỐ CỦA MỘT SỐ',
      //   cover: 'https://drive.google.com/thumbnail?id=1cRfuKLQzNYYhSpDY4r_JQB5tGF-1cfGd&sz=w1200-h800',
      // },
    ],
  },
  {
    className: 'Lớp 6',
    files: [
      {
        url: 'https://drive.google.com/file/d/15qSv5zrBWibaDT2ICFt-KU7D-4y-u1Xc/preview',
        title: 'Cộng, trừ số nguyên',
        description: '30_CỘNG, TRỪ SỐ NGUYÊN',
        cover: 'https://drive.google.com/thumbnail?id=15qSv5zrBWibaDT2ICFt-KU7D-4y-u1Xc&sz=w1200-h800',
      },
      {
        url: 'https://drive.google.com/file/d/1ZW5PjgPGDCVknRLInp7AH3v3YOMyMAZ_/preview',
        title: 'Cộng, trừ số nguyên và quy tắc dấu ngoặc',
        description: '31_CỘNG, TRỪ SỐ NGUYÊN VÀ QUY TẮC DẤU NGOẶC',
        cover: 'https://drive.google.com/thumbnail?id=1ZW5PjgPGDCVknRLInp7AH3v3YOMyMAZ_&sz=w1200-h800',
      },
      // {
      //   url: 'https://drive.google.com/file/d/1HUkNBUZ66twpw7RBAWnrcsxD0HaHRfep/preview',
      //   title: '09_Toán 6',
      //   description: '09_TỈ SỐ PHẦN TRĂM',
      //   cover: 'https://drive.google.com/thumbnail?id=1HUkNBUZ66twpw7RBAWnrcsxD0HaHRfep&sz=w1200-h800',
      // },
    ],
  },
];

export default function DocumentTabPage() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);

  const showModal = (file) => {
    setCurrentFile(file);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentFile(null);
  };

  return (
    <main className='lg:px-6'>
      <Tabs
        defaultActiveKey="1"
        tabPosition={"left"}
        style={{
          height: 400,
        }}
      >
        {classData.map((cls, index) => (
          <Tabs.TabPane tab={cls.className} key={index + 1}>
            <Row gutter={[16, 16]}>
              {cls.files.map((file, fileIndex) => (
                <Col
                  xs={24}
                  sm={12}
                  md={6}
                  key={fileIndex}
                >
                  <Card
                    hoverable
                    cover={
                      <div className="cover-container">
                        <img alt={`Thumbnail ${fileIndex + 1}`} src={file.cover} className="cover-image" />
                      </div>
                    }
                    style={{ height: '300px' }}
                    onClick={() => showModal(file.url)}
                  >
                    <Card.Meta title={file.title} description={file.description} />
                  </Card>
                </Col>
              ))}
            </Row>
          </Tabs.TabPane>
        ))}
      </Tabs>
      <Modal
        title="File Preview"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width="80%"
      >
        {currentFile && (
          <iframe
            src={currentFile}
            style={{ width: '100%', height: '500px', border: 'none' }}
          />
        )}
      </Modal>
    </main>
  );
}
