import React, { useState } from 'react';
import {SnippetsOutlined, ReadOutlined, FileDoneOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import DocumentTabPage from "./Document_Tab"
import BookPage from "./Book"
import ExamPage from "./Exam"
const pdfFiles = [
  // '/path/to/first.pdf',
  // '/path/to/second.pdf',
];

export default function DocumentsPage() {
  const [activeTab, setActiveTab] = useState('1');

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <main className='lg:px-6'>
      <h1 className='text-xl font-extrabold'>Tài liệu tham khảo</h1>
      <Tabs
        defaultActiveKey="1"
        activeKey={activeTab}
        onChange={handleTabChange}
        tabBarStyle={{ color: '#ffa500' }}
        items={[
          { label: 'Phiếu bài tập', icon: <SnippetsOutlined />, key: '1', children:<DocumentTabPage/> },
          // { label: 'Vở ghi học sinh', icon: <ReadOutlined />, key: '2', children: <BookPage/> },
          { label: 'Đề thi', icon:<FileDoneOutlined />, key: '3', children: <ExamPage/> },
        ]}
      />
    </main>
  );
}
